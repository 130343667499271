import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Seo from '../components/seo';
import '../styles/main.scss';

import Navigation from '../components/Navigation';
import WebsiteFooter from '../components/Footer';

import PageHeader from '../components/PageHeader';

const AboutIntro = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 3em;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .first {
    flex-basis: 45%;
    @media (max-width: 768px) {
      margin-bottom: 2em;
    }
  }
  .second {
    flex-basis: 45%;
    width: 100%;
    img {
      border-radius: 4px;
    }
  }
`;

const TeamSection = styled.section`
  display: flex;
  flex-direction: column;
  background: #f7faf8;
  padding: 3em;
  h2 {
    text-align: center;
    font-weight: 800;
  }
`;

const Team = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const TeamMember = styled.div`
  flex-basis: 31%;
  background: #e1eff6;
  overflow: auto
  padding: 1em 0 0;
  @media (max-width: 768px) {
    margin-bottom: 2em;
  }
  h4 {
    font-weight: 800;
    padding: 1rem;
    padding-bottom: 0;
  }
  h5 {
    font-weight: 800;
    font-size: 1em;
    padding: 1rem;
  }
  img {
    display: block;
  }
`;

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(relativePath: { eq: "about-header.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aboutImage: file(
        relativePath: { eq: "martin-downer-the-antiques-marketing-company.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      zara: file(relativePath: { eq: "zara.png" }) {
        childImageSharp {
          fixed(width: 300, height: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      maddie: file(relativePath: { eq: "maddie.png" }) {
        childImageSharp {
          fixed(width: 300, height: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      frances: file(relativePath: { eq: "frances.png" }) {
        childImageSharp {
          fixed(width: 300, height: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <>
      <Seo title="About" />
      <Navigation showLogo background />
      <PageHeader title="About" data={data} />
      <AboutIntro className="container">
        <div className="first">
          <p>
            The Antique Marketing Company provides a bespoke service to each
            individual dealer. No matter how large or small the task, we aim to
            offer an accessible approach to helping dealers understand and
            navigate the somewhat unknown online world. With our relationships
            and contacts, we stand out and deliver results for the antique
            industry.
          </p>
        </div>
        <div className="second">
          <Img
            fluid={data.aboutImage.childImageSharp.fluid}
            alt="Martin Downer The Antiques Marketing Company"
            style={{ height: '400px' }}
          />
        </div>
      </AboutIntro>
      <WebsiteFooter />
    </>
  );
};

export default AboutPage;
